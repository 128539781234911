import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { PageProps } from "gatsby";
import styled from "styled-components";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import DocumentationSection from "components/docs/DocumentationSection";
import { CenteredDoc } from "../target-fields";
import IntegWideTargetFieldConfig from "../../../../assets/images/docs/guides/field-mapping/IntegWideTargetFieldConfig.svg";
import LINKS from "components/docs/navigation/links";
import { AsideTitle } from "./for-linked-account";
/**
 * Creates a Target Fields page for Field Mapping guide
 */
const CreateFieldMappingAcrossAnIntegration = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Mapping Across an Integration";
  const description =
    "Create a Field Mapping that applies across all Linked Accounts for a specific app. This is useful if you want to map an additional Common Model field to a target field that is standard to a particular integration, without creating individual Field Mappings for each Linked Account. ";

  const VerticalFlexBox = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const FormattedSubtitle = () => (
    <VerticalFlexBox>
      <p>Create a Field Mapping that applies across all Linked Accounts for a specific app.</p>
      <p>
        This is useful if you want to map an additional Common Model field to a target field that is
        standard to a particular integration, without creating individual Field Mappings for each
        Linked Account.
      </p>
    </VerticalFlexBox>
  );

  const SubtitleComponent = {
    *[Symbol.iterator]() {
      yield <FormattedSubtitle />;
    },
  };

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={SubtitleComponent as any} />
      <DocumentationSection title="Create a Field Mapping Across an Integration">
        <p>
          Integration-wide Field Mappings are available for frequently used origin fields – if
          you're not seeing the field you need, contact{" "}
          <a href="mailto:support@merge.dev?subject = Field Mapping Origin Field Inquire">
            support@merge.dev
          </a>
          . To see what integrations Merge supports for integration-wide mappings, visit our{" "}
          <a
            href={
              LINKS.HELP_CENTER.linkTo +
              "/en/articles/7236095-supported-integrations-for-integration-wide-field-mapping"
            }
            target="_blank"
          >
            Help Center
          </a>
          .
        </p>
        <p className="mb-0">
          Click on a target field in your Target Fields Configuration to view its details. Under{" "}
          <strong>Field Mappings by Integration</strong>, you can search for and select an
          integration for which to create a mapping. Click <strong>+Field Mapping</strong> to select
          an origin field to map to the target field.
        </p>
        <CenteredDoc className="mt-9 mb-5">
          <IntegWideTargetFieldConfig className="rounded-lg" />
        </CenteredDoc>
        <p>
          This integration-wide mapping will be created by default for any existing and new Linked
          Accounts of that integration. It will appear labeled as an{" "}
          <strong>organization-wide [integration] mapping</strong> in each Linked Account’s Field
          Mapping page in your dashboard and the target field will not appear in Link as mappable
          for your end users unless you enable overrides.
        </p>
      </DocumentationSection>
      <aside className="my-8">
        <AsideTitle>Linked Account-level overrides</AsideTitle>
        <br />
        <p>
          You can enable Linked Account-level overrides for an integration-wide Field Mapping by
          enabling the <strong>Overrides</strong> toggle.
        </p>{" "}
        <p>
          If overrides are enabled for an integration-wide mapping, you can select a different
          origin field within each Linked Account&apos;s Field Mapping page and your end users can
          select a different origin field in Link.
        </p>{" "}
        <p>
          An overridden mapping will appear labeled as a{" "}
          <strong>Linked Account-specific mapping</strong> in the Linked Account&apos;s Field
          Mapping page in your dashboard.
        </p>
      </aside>

      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default CreateFieldMappingAcrossAnIntegration;
